<template>
  <div>
    <a-button type="primary" @click="showModal"> 新建数值模拟量规则 </a-button>
    <a-divider />
    <a-modal
      :visible="visible"
      title="新建数值模拟量规则"
      okText="确认"
      @cancel="cancel"
      @ok="create"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
      >
        <a-form-item label="behaviour">
          <a-input
            placeholder="请输入你要定义的行为"
            v-decorator="[
              'behaviour',
              {
                rules: [
                  { required: true, message: 'Please input your behaviour!' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="modelId">
          <a-input
            placeholder="请输入物模型id"
            v-decorator="[
              'modelId',
              {
                rules: [
                  { required: true, message: 'Please input your modelId!' },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="id">
          <a-select
            v-decorator="[
              'id',
              {
                rules: [
                  { required: true, message: 'Please select your gender!' },
                ],
              },
            ]"
            placeholder="在下面选一个选项"
            @change="handleSelectChange"
          >
            <a-select-option value="0"> 0 </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="topics">
          <a-input
            placeholder="请输入订阅的主题"
            v-decorator="[
              'topics',
              {
                rules: [
                  { required: true, message: 'Please input your topics' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary" html-type="submit"> Submit </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "NewRules",
  data() {
    return {
      mypath: "",
      visible: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    create() {
      this.visible = false;
    },
    cancel() {
      this.visible = false;
    },
    handleSubmit(e) {
      let this_1 = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //  console.log('Received values of form: ', values);
          this_1.mypath = window.g.new_pro_control_digital;
          //this_1.mypath = "https://api.rslly.top/api/v2/ProductDigitalControl";
          axios({
            url: this_1.mypath,
            method: "post",
            //发送格式为json
            headers: {
              Authorization: localStorage.getItem("Authorization"),
            },
            data: {
              behavior: values.behaviour,
              modelId: parseInt(values.modelId),
              id: 0,
              topic: values.topics,
            },
          }).then((response) => {
            this_1.visible = false;
            console.log(response.data);
            if (response.data.errorCode == 200) {
              this_1.$message.success("创建成功");
            } else if (response.data.errorCode === 2001) {
              this_1.$message.error("权限认证失效，请重新登录");
              this_1.$router.push("/Login");
            }else{
              this_1.$message.error("参数错误，创建失败");
            }
          });
        }
      });
    },
  },
};
</script>