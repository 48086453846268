<template>
  <div>
    <a-divider />
    <a-modal
      :visible="visible"
      title="执行模拟量行为"
      okText="确认"
      @cancel="cancel"
      @ok="create"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
      >
        <!-- <a-form-item label="behaviour">
               <a-input
                 placeholder="请输入你要定义的行为" v-decorator="['behaviour', { rules: [{ required: true, message: 'Please input your behaviour!' }] }]"
               />
             </a-form-item> -->

        <!--a-form-item label="id">
          <a-select
            v-decorator="[
              'id',
              {
                rules: [
                  { required: true, message: 'Please select your gender!' },
                ],
              },
            ]"
            placeholder="在下面选一个选项"
            @change="handleSelectChange"
          >
            <a-select-option value="0"> 0 </a-select-option>
          </a-select>
        </a-form-item-->

       
        <a-form-item
          v-for="(key, index) in list"
          :key="index"
          :label="`${key}`"
        >
          <a-input
            placeholder="请输入模拟量值"
            v-decorator="[
              `digital[${key}]`,
              {
                rules: [
                  { required: true, message: 'Please input your digital!' },
                ],
              },
            ]"
          />
        </a-form-item>

       
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary" html-type="submit"> 执行 </a-button>
          <br/><span>{{code}}</span>
          <a-button type="primary" @click="copyOrderId()"> 复制代码 </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["myvisible", "item"],
  data() {
    return {
      mypath: "",
      mypath2:"",
      keys: [0, 1, 2],
      list: ["temp", "temp2", "temp3"],
      visible: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
      behavior:"",
      digitalId:0,
      modelId:0,
      code:""
    };
  },
  created() {
    //this.form = this.$form.createForm(this);
    // 通过setTimeout模拟网络请求
    /* setTimeout(
      (res) => {
        this.form.getFieldDecorator("keys", {
          initialValue: res.keys,
          preserve: true,
        });
       // this.show = true;
        this.$nextTick(() => {
          this.form.setFieldsValue(res);
        });
      },
      2000,
      {
        keys: [0, 1, 2, 3],
        names: ["窗前明月光", "疑似地上霜", "举头望明月", "低头思故乡"],
        test: "test",
      }
    );*/
  },
  watch: {
    myvisible(n, o) {
      this.visible = n;
    },
    item(n,o){
       console.log(n.id)
       this.mypath2 = "https://api.rslly.top/api/v2/ProductDigitalProtocolJsonKey";
      axios({
            url: this.mypath2,
            method: "get",
            //发送格式为json
            headers: {
              Authorization: localStorage.getItem("Authorization"),
            },
            params: {
             digitalId: parseInt(n.id)
            },
          }).then((response) => {
            
             console.log(response.data)
            if (response.data.errorCode == 200) {
             // this_1.$message.success("创建成功");
             this.list=response.data.data
             this.behavior=n.behavior
             this.digitalId= parseInt(n.id)
             this.modelId= parseInt(n.modelId)       
             //this.code = "digitalcontrol(" + this.item.id+",\""+this.key+"\""+")"+";";
      
             //this.visible=true
             if(response.data.data.length==0){
              this.$message.error("协议未配置");
               this.visible=false
             }
            } else {
               this.$message.error("请求失败");
               this.visible=false
            }
          });
    }
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    create() {
      this.visible = false;
    },
    cancel() {
      this.visible = false;
    },
    copyOrderId() {
      let data=[]
      let ok=true;
      this.form.validateFields((err, values) => {
         let undefined=false;
          console.log(values.digital);
          for( let key in values.digital){
            console.log(values.digital[key.toString()])
            if(values.digital[key.toString()]==null||values.digital[key.toString()]==""){
              undefined=true;
            }
            data.push("\""+values.digital[key.toString()]+"\"")
          }
          if(undefined){
          this.$message.error("缺失参数")
          ok=false;
        }
      });
      if(!ok)return;
      console.log(data.toString())
      var input = document.createElement("input"); // 创建input对象
      input.value = "digitalControl(" + this.item.id+",["+data.toString()+"]"+")"+";";; // 设置复制内容
      this.code=input.value;
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("成功复制到剪贴板");
      
    },
    handleSubmit(e) {
      let this_1 = this;
      //console.log("修改规则欣慰", this_1.item2.behaviour);
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //  console.log('Received values of form: ', values);
          this_1.mypath = window.g.new_doDigitalrules;
          let data=[]
         // console.log(values.digital);
          for( let key in values.digital){
            console.log(values.digital[key.toString()])
            data.push(values.digital[key.toString()])
          }
          axios({
            url: this_1.mypath,
            method: "post",
            //发送格式为json
            headers: {
              Authorization: localStorage.getItem("Authorization"),
            },
            data: {
              behavior: this.behavior,
              value: data,
              modelId: this.modelId,
            },
          }).then((response) => {
            this_1.visible = false;
            // console.log(response.data.code)
             if (response.data.errorCode == 200) {
              this_1.$message.success("发送成功");
            } else if (response.data.errorCode === 2001) {
              this_1.$message.error("权限认证失效，请重新登录");
              this_1.$router.push("/Login");
            }else{
              this_1.$message.error("参数错误，创建失败");
            }
          });
        }
      });
    },
  },
};
</script>